@import "./../../variables.scss";

@font-face {
    font-family: HelveticaBold;
    src: url(../Fonts/helvetica-bold.ttf);
}

@font-face {
    font-family: HelveticaLight;
    src: url(../Fonts/helvetica-light.ttf);
}

@font-face {
    font-family: Helvetica;
    src: url(../Fonts/helvetica-normal.ttf);
}

/*********common css*********/

body {
    margin: 0 auto;
    font-family: Helvetica;
    user-select: none;
    font-size: 14px;
}
.footer-wrapper {
    padding: 20px;
    font-size: 12px;
    text-align: center;
}
.material-title {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 28px;
    padding: 35px 4% 0;
    min-height: 400px;
    font-family: HelveticaBold;
    color: white;
    background: linear-gradient(to right, $primary-dark-color 45%, #ee4b2b);
    text-shadow: 0 2px 3px $primary-dark-color;
}

.material-card {
    position: relative;
    left: 0;
    right: 0;
    top: 90px;
    /*margin: 0 4% 1%;*/
    margin-left     : 4%;
    margin-right    : 4%;
    margin-bottom   : 90px;
    padding         : 3%;
    min-height: 400px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
}

.material-footer {
    position: relative;
    top: 100px;
    margin: 2.5% 4% 2%;
    font-size: 12px;
}

/*********common css ends*********/

@media only screen and (max-width: 550px) {
    p,
    li,
    ul,
    b,
    strong,
    pre {
        font-size: 13px !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 17px !important;
    }
    .title {
        font-size: 17px !important;
    }
    .material-title {
        font-size: 20px !important;
    }
    .sub-title {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 900px) {
    .material-card {
        left: 0;
        right: 0;
        margin: 0 auto;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
        padding: 5%;
        margin-bottom: 90px;
    }
}

