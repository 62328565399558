@import "./../../variables.scss";

.menu-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    position: sticky;
    z-index: 10001;
    background: #fff;

    .menus {
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn-sideMenu {
            cursor: pointer;
            margin-right: 20px;
            text-align: center;
            color: $primary-color;
        }

        .header-title {
            padding: 0 15px;
            color: $primary-color;
        }
    }

    .menu-item {
        height: 60px;
        width: 70px;
        text-align: center;
        line-height: 60px;
        padding: 0 15px;
        text-decoration: none !important;
        font-weight: 500;
        font-size: 18px;
        color: $primary-color;
        border-bottom: 7px solid #fff;

        &.active {
            border-bottom-color: $secondary-color;
        }
    }
}

.sideMenu-wrapper {
    .menu-item {
        text-decoration: none !important;
        padding: 10px 25px;
        margin: 5px 0;
        font-weight: 500;
        font-size: 16px;
        color: $primary-color;

        &.active {
            color: $secondary-color;
        }
    }
}