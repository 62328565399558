
.card-image {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 200px;
    transition: height 1s;
    object-fit: none;
    /* Do not scale the image */
    object-position: center;
    /* Center the image within the element */
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: ellipsis;
    width: 100%;
    height: 100%;
    transition: .5s ease;
}

.card-image-container {
    position: relative;
}

.card:hover .overlay {
    box-shadow: inset 0 0 0 110px rgba(0, 0, 0, 0.7),
        0 1px 2px rgba(0, 0, 0, 0.7);
}

.card-image-container:hover .overlay {
    box-shadow: inset 0 0 0 110px rgba(0, 0, 0, 0.9),
        0 1px 2px rgba(0, 0, 0, 0.9);
}

.card-image-container:hover .text {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.text {
    position: absolute;
    top: 45%;
    width: 100%;
    opacity: 0;
    white-space: break-word;
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: bolder;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    border-radius: 50%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);

    -webkit-backface-visibility: hidden;
    /*for a smooth font */
}
