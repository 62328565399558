@import "./variables.scss";
@import "./Assets/Styles/common.scss";
@import "./Assets/Styles/PropertyFest.scss";
@import "./Assets/Styles/projects_details.scss";
@import "./Assets/Styles/gallery.scss";
@import "./Assets/Styles/HomeWrapper.scss";
@import "./Assets/Styles/Menu.scss";
@import "react-responsive-carousel/lib/styles/carousel.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.title-box {
  color: $secondary-color !important;
  border-bottom: 2px solid $primary-color;
  padding: 5px 0;
}

.sub-title-box {
  color: $secondary-color !important;
  border-left: 5px $primary-color;
  padding: 5px 0;
}