.garden-wrapper {
    height: 100%;
    margin-top: -95px;
    background-image: url("./../Images/NavratriOffer24/bg-map.png");

    .bg-map {
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    .info-container {
        position: relative;
        margin-top: 90px;
        width: 100%;
        margin-left: 2px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 50px 50px 0;

        .btn-register-now {
            cursor: pointer;
            text-align: center;
            margin-top: -10px;
            padding: 15px 20px;
            border-radius: 5px;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
            background-color: #fff;
            transition: 0.2s ease-in-out;

            &:hover {
                box-shadow: 0 0 20px rgba(0, 0, 0, 1);
            }
        }
    }

    .credai-details {
        position: relative;
        width: 100%;
        padding: 30px;
        background-color: #24338B;
        z-index: 999;

        .ic-social-media {
            height: 35px;
            width: 35px;
            color: #fff;
        }

        .qr-code-wrapper {
            // position: absolute;
            // top: -80px;
            // right: 25px;
        }
    }
}