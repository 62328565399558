.imageContainer {
    img {
        height: 150px;
        width: 220px;
        opacity: 0.9;
        margin: 3px;
        cursor: pointer;
        /*box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);*/
        -webkit-filter: blur(0px);
        /* Safari 6.0 - 9.0 */
        filter: blur(0px);
        object-fit: cover;
        /* Do not scale the image */
        object-position: center;
        /* Center the image within the element */
        border-bottom: transparent 3px solid;
        border-top: transparent 3px solid;
        padding: 2px;

        &:hover {
            /*-webkit-filter: blur(2px);  Safari 6.0 - 9.0 
            filter: blur(2px);*/
            border-bottom: rgba(0, 0, 0, 1) 3px solid;
            border-top: rgba(0, 0, 0, 1) 3px solid;
            padding-bottom: 2px;
            padding-top: 2px;
        }
    }
}


@media only screen and (max-width: 800px) {
    .imageContainer {
        img {
            height: 150px;
            width: 150px;
        }
    }
}


@media only screen and (max-width: 500px) {
    .imageContainer {
        img {
            height: 70px;
            width: 70px;
        }
    }
}